<template>
  <div class="video">
    <div class="slogan"></div>
    <a href="javascript:;" class="btn-more" @click="clickMore" v-if="false"></a>
    <ul class="video-list">
      <li v-for="(item, index) in videoList" :key="index" @click="openVideo(item.jump_url)">
        <div class="cover">
          <img :src="`${api}${item.advertisementArr[0].advertisement_img}`" alt="视频封面" />
        </div>
        <p class="title">{{item.title}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { fetchPost } from "@/api/https"

export default {
  name: 'Video',
  props: {

  },
  data() {
    return {
      api: 'https://admin.ilogin.com.cn/',
      videoList:[
        // {
        //   vid: 'https://www.taptap.com/video/2379489',
        //   cover: require('../../assets/images/home/img-video1.png'),
        //   title: '武侠乂最详细空牙教学'
        // },
        // {
        //   vid: 'https://www.taptap.com/video/2336255',
        //   cover: require('../../assets/images/home/img-video2.png'),
        //   title: '[教学]长刀连招思路'
        // },
        // {
        //   vid: 'https://www.taptap.com/video/2382259',
        //   cover: require('../../assets/images/home/img-video3.png'),
        //   title: '武侠乂新手教程'
        // },
        // {
        //   vid: 'https://www.taptap.com/video/2386451',
        //   cover: require('../../assets/images/home/img-video4.png'),
        //   title: '长枪与重剑打法思路与瞬切武器教学'
        // },
      ]
    }
  },
  methods:{
    clickMore(){
      let routeUrl =this.$router.resolve({path: '/MediaList'})
      window.open(routeUrl.href, '_blank');
    },
    openVideo(item){
      window.open(item, '_blank');
    },
    handleGetVideoList(){
      fetchPost('indexVideoList').then(response => {
        this.videoList = response.data.data.data
        // console.log(response)
      })
    }
  },
  mounted(){
    this.handleGetVideoList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .video{
    height:1080px;
    padding-top:28px;
    position: relative;
  }

  .slogan{
    width:600px;
    height:147px;
    background:url("../../assets/images/home/title-video.png") no-repeat center/contain;
    margin:0 auto 70px;
  }

  .video-list{
    width:1522px;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .video-list li{
    width:489px;
    margin-bottom:30px;
    cursor: pointer;
  }

  .video-list .cover{
    width:100%;
    height:285px;
    background:url("../../assets/images/home/bg-videoCover.png") no-repeat center/cover;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:10px;
  }

  .cover img{
    width:460px;
    height:260px;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
  }

  .video-list .cover::before{
    content: '';
    width: 456px;
    height: 250px;
    border: 1px solid #EDC025;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 5;
    opacity: 0;
  }

  .video-list .cover::after{
    content: '';
    width:111px;
    height:111px;
    background:url("../../assets/images/home/icon-play.png") no-repeat center/contain;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 5;
  }

  .video-list .title{
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    padding:0 10px;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
  }

  .video-list li:hover .cover::after{
    opacity: 0;
  }

  .video-list li:hover .cover::before{
    opacity: 1;
  }

  .video-list li:hover .cover img{
    filter: none;
  }

  .video-list li:hover .title{
    color: #FBEB00;
  }

  .btn-more{
    width:152px;
    height:51px;
    background:url("../../assets/images/home/btn-more.png") no-repeat center/contain;
    position: absolute;
    top:168px;
    right:236px;
  }

  .btn-more:hover{
    transform: scale(1.1);
  }
</style>
