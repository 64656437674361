<template>
  <div class="home" :style="{ backgroundImage: `url( ${bg} )` }">
    <Header :active="activeNav"/>
    <kv/>
    <News/>
    <Role/>
    <weapon/>
    <Video/>
    <Media/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Kv from "../components/home/Kv"
import Header from "../components/common/Header";
import Media from "../components/home/Media";
import News from "../components/home/News";
import Role from "../components/home/Role";
import Weapon from "../components/home/Weapon";
import Video from "../components/home/Video";
import Footer from "../components/common/footerBat"
import {fetchPost} from "@/api/https"

export default {
  name: 'Home',
  components: {
    Header,
    Kv,
    News,
    Role,
    Weapon,
    Video,
    Media,
    Footer
  },
  data() {
    return {
      activeNav: 0,
      bg: ''
    }
  },
  // create(){
  //   this.getBG()
  // },
  mounted() {
    this.getBG()
  },
  methods: {
    getBG() {
      fetchPost('baseInfo').then(response => {
        this.bg = 'https://admin.ilogin.com.cn/' + response?.data?.data[0]?.optionvalue
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  width: 100%;
  min-height: 6485px;
  /*min-height:6485px;*/
  /*overflow-x: hidden;*/
  /*background: url("../assets/images/home/bg.jpg") no-repeat center top/100% 6494px;*/
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center 1080px;
  background-size: 100% 5414px;
  position: relative;
}
</style>
