<template>
  <div class="weapon">
    <div class="slogan"></div>
    <div class="tab-box">
      <a href="javascript:;" class="icon-arr icon-arr-prev weapon-tab-prev"></a>
      <div class="tab-list weapon-tab" v-if="weaponList.length !== 0">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in weaponList" :key="index" :class="['swiper-slide tab-item', index === showWeapon ? 'active' : null]" @click="changeItem(index)">
            {{item.title}}
          </div>
        </div>
      </div>
      <a href="javascript:;" class="icon-arr icon-arr-next weapon-tab-next"></a>
    </div>
    <div class="weapon-box" v-if="weaponList.length !== 0">
      <img :class="['weapon-slogan', 'animation', showAnimation ? 'animation_bounceIn' : null]" :src="`${api}${weaponList[showWeapon].intro_img}`" alt="武器介绍" />
      <img :class="['weapon-img', 'animation', showAnimation ? 'animate__fadeInRightBigTC' : null]" :src="`${api}${weaponList[showWeapon].info_img}`" alt="武器立绘" />
    </div>
  </div>
</template>

<script>
import  Swiper, {Navigation} from 'swiper'
Swiper.use([Navigation])
import { fetchPost } from "@/api/https"
export default {
  name: 'Weapon',
  props: {

  },
  data() {
    return {
      api: 'https://admin.ilogin.com.cn/',
      showAnimation: false,
      weaponList:[
        // {
        //   name:'长枪',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-cq.png'),
        //     className: 'slogan-cq'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-cq.png'),
        //     className: 'weapon-cq'
        //   }
        // },
        // {
        //   name:'单刀',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-dd.png'),
        //     className: 'slogan-dd'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-dd.png'),
        //     className: 'weapon-dd'
        //   }
        // },
        // {
        //   name:'单剑',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-dj.png'),
        //     className: 'slogan-dj'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-dj.png'),
        //     className: 'weapon-dj'
        //   }
        // },
        // {
        //   name:'双剑',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-sj.png'),
        //     className: 'slogan-sj'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-sj.png'),
        //     className: 'weapon-sj'
        //   }
        // },
        // {
        //   name:'长刀',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-cd.png'),
        //     className: 'slogan-cd'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-cd.png'),
        //     className: 'weapon-cd'
        //   }
        // },
        // {
        //   name:'双刺',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-sc.png'),
        //     className: 'slogan-sc'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-sc.png'),
        //     className: 'weapon-sc'
        //   }
        // },
        // {
        //   name:'重剑',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-zj.png'),
        //     className: 'slogan-zj'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-zj.png'),
        //     className: 'weapon-zj'
        //   }
        // },
        // {
        //   name:'长剑',
        //   slogan: {
        //     src: require('../../assets/images/home/weapon/slogan-cj.png'),
        //     className: 'slogan-cj'
        //   },
        //   img: {
        //     src: require('../../assets/images/home/weapon/weapon-cj.png'),
        //     className: 'weapon-cj'
        //   }
        // },
      ],
      showWeapon: 0,
      weaponTabSwiper: null
    }
  },
  methods: {
    // 移入
    changeItem(index) {
      if(this.showWeapon === index) return;
      if (!this.showAnimation) {
        this.showWeapon = index;
        this.showAnimation = true;
        setTimeout(() => {
          this.showAnimation = false;
        }, 500);
      }
    },
    createWeaponTabSwiper(){
      const _this = this;
      _this.weaponTabSwiper = new Swiper('.weapon-tab', {
        direction : 'vertical',
        slidesPerView: 'auto',
        observer:true,
        observeParents:true,
        observeSlideChildren:true,
        navigation: {
          nextEl: ".weapon-tab-next",
          prevEl: ".weapon-tab-prev",
        },
        touchMoveStopPropagation : true,
      })
    },
    handleGetWeapon(){
      fetchPost('armsInfo').then(response => {
        // this.noticeList = response.data.data.data
        this.weaponList = response.data.data
        setTimeout(() =>{
          this.createWeaponTabSwiper();
        },300)
      })
    }
  },
  mounted: function () {
    this.handleGetWeapon();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .weapon{
    height:1080px;
    position: relative;
    padding-top:90px;
  }

  .slogan{
    width:600px;
    height:147px;
    background:url("../../assets/images/home/title-weapon.png") no-repeat center/contain;
    margin:0 auto 80px;
  }

  .weapon .weapon-box{
    position: relative;
    width:100%;
    height:calc(100% - 96px - 80px);
    pointer-events: none;
  }

  .weapon-box .weapon-img{
    width:688px;
    height:806px;
    position: absolute;
    bottom:100px;
    right:85px;
  }
  .weapon-box .weapon-slogan{
    position: absolute;
    left:465px;
    top:0;
    z-index: 5;
    width:596px;
    height:490px;
  }

  .weapon-box .weapon-cq{
    width:calc( 982px * 0.9);
    height:calc( 870px * 0.9);
    bottom:60px;
    right:48px;
  }

  .weapon-box .weapon-dd{
    width:777px;
    height:633px;
    right:100px;
    bottom:85px;
  }

  .weapon-box .weapon-dj{
    width:756px;
    height:604px;
    bottom:100px;
    right:85px;
  }

  .weapon-box .weapon-sj{
    width:733px;
    height:691px;
    right:100px;
    bottom:80px;
  }

  .weapon-box .weapon-cd{
    width:778px;
    height:590px;
    bottom:135px;
    right:138px;
  }

  .weapon-box .weapon-sc{
    width:603px;
    height:660px;
    right: 225px;
    bottom:110px;
  }

  .weapon-box .weapon-cj{
    width:802px;
    height:694px;
    right: 1px;
    bottom:100px;
  }

  .weapon-box .weapon-zj{
    width:705px;
    height:627px;
    right: 1px;
    bottom:100px;
  }

  .weapon-box .slogan-cq{
    height:496px;
  }

  .weapon-box .slogan-dd{
    height:462px;
    width:542px;
  }

  .weapon-box .slogan-dj{
    height:497px;
  }

  .weapon-box .slogan-sj{
    height:461px;
  }

  .weapon-box .slogan-cd{
    height:460px;
    width:542px;
  }

  .weapon-box .slogan-sc{
    height:496px;
  }

  .weapon-box .slogan-cj{
    height:497px;
  }

  .weapon-box .slogan-zj{
    width:557px;
    height:462px;
  }
</style>
