import axios from 'axios'
const api = 'https://wuxiayi.ilogin.com.cn/backend/website/information/'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.timeout = 10000;                        //响应时间
// axios.defaults.baseURL = 'https://admin.ilogin.com.cn/website/information/';   //配置接口地址

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    //在发送请求之前做某件事
    //携带请求头
    let token = window.localStorage.getItem("accessToken")
    // console.log("token:"+token);
    //下面两种方式都行
    // config.headers.accessToken = token;
    config.headers['accessToken'] = token

    return config;
},(error) =>{
    console.log('错误的传参')
    return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
    //对响应数据做些事
    if(!res.data.success){
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    console.log('网络异常')
    return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost(url, data) {
    const link = api + url
    return new Promise((resolve, reject) => {
        axios.post(link, data)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function fetchGet(url, data) {
    const link = api + url
    return new Promise((resolve, reject) => {
        axios.get(link, data)
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export default {
    fetchPost,
    fetchGet,
}
