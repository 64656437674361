<template>
  <div class="role">
    <div class="slogan"></div>
    <div class="tab-box">
      <a href="javascript:;" class="icon-arr icon-arr-prev role-tab-prev"></a>
      <div class="tab-list role-tab" v-if="roleList.length !== 0">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in roleList" :key="index" :class="['swiper-slide tab-item', index === showRole ? 'active' : null, item.title === '佐佐玄之助' ? 'tab-xzz' : '' ]" @click="changeItem(index)">{{item.title}}</div>
        </div>
      </div>
      <a href="javascript:;" class="icon-arr icon-arr-next role-tab-next"></a>
    </div>
    <div class="role-box" v-if="roleList.length !== 0">
      <img :class="['role-des', 'animation', showAnimation ? 'animate__fadeIn' : null]" :src="`${api}${roleList[showRole].intro_img}`" alt="人物介绍" />
      <img :class="['role-img', 'animation', showAnimation ? 'animate__fadeInRightBig' : null]" :src="`${api}${roleList[showRole].info_img}`" alt="人物立绘" />
    </div>
  </div>
</template>

<script>
import  Swiper, {Navigation} from 'swiper'
Swiper.use([Navigation])
import { fetchPost } from "@/api/https"

export default {
  name: 'Role',
  props: {

  },
  data() {
    return {
      showAnimation: false,
      api: 'https://admin.ilogin.com.cn/',
      roleList:[
        // {
        //   name: '丁玄',
        //   role: {
        //     img: require('../../assets/images/home/role/role-dx.png'),
        //     className: 'role-dx'
        //   },
        //   des: {
        //     img: require('../../assets/images/home/role/des-dx.png'),
        //     className: 'des-dx'
        //   }
        // },
        // {
        //   name: '丁媛',
        //   role: {
        //     img: require('../../assets/images/home/role/role-dy.png'),
        //     className: 'role-dy'
        //   },
        //   des: {
        //     img: require('../../assets/images/home/role/des-dy.png'),
        //     className: 'des-dy'
        //   }
        // },
        // {
        //   name: '沈正',
        //   role: {
        //     img: require('../../assets/images/home/role/role-sz.png'),
        //     className: 'role-sz'
        //   },
        //   des: {
        //     img: require('../../assets/images/home/role/des-sz.png'),
        //     className: 'des-sz'
        //   }
        // },
        // {
        //   name: '裴洛音',
        //   role: {
        //     img: require('../../assets/images/home/role/role-ply.png'),
        //     className: 'role-ply'
        //   },
        //   des: {
        //     img: require('../../assets/images/home/role/des-ply.png'),
        //     className: 'des-ply'
        //   }
        // },
        // {
        //   name: '帖木儿',
        //   role: {
        //     img: require('../../assets/images/home/role/role-tme.png'),
        //     className: 'role-tme'
        //   },
        //   des: {
        //     img: require('../../assets/images/home/role/des-tme.png'),
        //     className: 'des-tme'
        //   }
        // },
        // {
        //   name: '佐佐'+'\n'+'玄之助',
        //   role: {
        //     img: require('../../assets/images/home/role/role-xzz.png'),
        //     className: 'role-xzz'
        //   },
        //   des: {
        //     img: require('../../assets/images/home/role/des-xzz.png'),
        //     className: 'des-xzz'
        //   }
        // },
      ],
      showRole: 0,
      roleTabSwiper: null
    }
  },
  methods: {
    // 切换显示
    changeItem(index) {
      if(this.showRole === index) return;
      if (!this.showAnimation) {
        this.showRole = index;
        this.showAnimation = true;
        setTimeout(() => {
          this.showAnimation = false;
        }, 500);
      }
    },
    createRoleTabSwiper(){
      const _this = this;
      _this.roleTabSwiper = new Swiper('.role-tab', {
        direction : 'vertical',
        slidesPerView: 'auto',
        observer:true,
        observeParents:true,
        observeSlideChildren:true,
        navigation: {
          nextEl: ".role-tab-next",
          prevEl: ".role-tab-prev",
        },
        touchMoveStopPropagation : true,
      })
    },
    handleGetRole(){
      fetchPost('roleInfo').then(response => {
        this.roleList = response.data.data
        setTimeout(() =>{
          this.createRoleTabSwiper();
        },300)
      })
    }
  },
  mounted: function () {
    this.handleGetRole()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .role{
    height:1080px;
    position: relative;
    padding-top:70px;
  }

  .slogan{
    width:600px;
    height:147px;
    background:url("../../assets/images/home/title-role.png") no-repeat center/contain;
    margin:0 auto 80px;
  }

  .role .role-box{
    position: relative;
    width:100%;
    height:calc(100% - 93px - 80px);
    pointer-events: none;
  }

  .role-box .role-img{
    width:804px;
    height:758px;
    position: absolute;
    top:0;
    right:0;
  }
  .role-box .role-des{
    width:550px;
    height:780px;
    position: absolute;
    left:405px;
    top:-20px;
    z-index: 5;
  }
  /*.role-box .role-dx{*/
  /*  width:812px;*/
  /*  height:818px;*/
  /*}*/
  /*.role-box .role-dy{*/
  /*  width:803px;*/
  /*  height:807px;*/
  /*  right:0;*/
  /*}*/
  /*.role-box .role-sz{*/
  /*  width:629px;*/
  /*  height:813px;*/
  /*}*/
  /*.role-box .role-ply{*/
  /*  width:804px;*/
  /*  height:822px;*/
  /*  right: 0;*/
  /*}*/
  /*.role-box .role-tme{*/
  /*  width:775px;*/
  /*  height:839px;*/
  /*}*/
  /*.role-box .role-xzz{*/
  /*  width:788px;*/
  /*  height:814px;*/
  /*}*/
  /*.role-box .des-dx{*/
  /*  width:549px;*/
  /*  height:707px;*/
  /*}*/
  /*.role-box .des-dy{*/
  /*  width:531px;*/
  /*  height:715px;*/
  /*}*/
  /*.role-box .des-sz{*/
  /*  width:558px;*/
  /*  height:747px;*/
  /*}*/
  /*.role-box .des-ply{*/
  /*  width:598px;*/
  /*  height:715px;*/
  /*}*/
  /*.role-box .des-tme{*/
  /*  width:558px;*/
  /*  height:714px;*/
  /*}*/
  /*.role-box .des-xzz{*/
  /*  width:574px;*/
  /*  height:680px;*/
  /*}*/
</style>
