<template>
  <div class="header">
    <a href="javascript:;" class="logo" ></a>
    <ul class="nav">
      <li v-for="(item, index) in navList" :key="index" :class="['nav-item', index === active ? 'active' : '']">
        <a href="javascript:;" @click="click(index, item.link)">
          {{item.name}}
          <ul v-if="item.name === '关注我们'" class="contact">
<!--            <li v-if="false" v-for="(item, index) in contactList" class="item" :key="index">-->
<!--              <img :src="item.img" :alt="item.name" />-->
<!--              <p>{{item.name}}</p>-->
<!--            </li>-->
          </ul>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    active: Number
  },
  data() {
    return {
      navList: [
        {
          name: '首页',
          link: ''
        },
        {
          name: '游戏资讯',
          link: 'newsList'
        },
        {
          name: '游戏资料',
          link: 'javascript:;'
        },
        {
          name: '视听中心',
          link: 'mediaList'
        },
        {
          name: '关注我们',
          link: 'javascript:;'
        },
      ],
      contactList: [
        {
          name: '公众号',
          img: require('../../assets/images/common/code/code-gzh.png')
        },
        {
          name: '微博',
          img: require('../../assets/images/common/code/code-wb.png')
        },
        {
          name: 'QQ',
          img: require('../../assets/images/common/code/code-game.png')
        },
        {
          name: 'B站',
          img: require('../../assets/images/common/code/code-bz.png')
        },
        {
          name: '抖音',
          img: require('../../assets/images/common/code/code-dy.png')
        },
      ]
    }
  },
  methods:{
    openDetails(name){
      let routeUrl =this.$router.resolve({path: `/${name}`})
      window.open(routeUrl.href, '_blank');
    },
    click(i, l){
      if ( i === 2) {
        alert('敬请期待')
      } else{
        this.openDetails(l)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header{
    width:100%;
    height: 94px;
    background: rgba(0,0,0,.5);
    position: fixed;
    top:0;
    left:50%;
    transform: translateX(-50%);
    z-index:10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo{
    width:94px;
    height:46px;
    background:url("../../assets/images/common/logo.png") no-repeat center/cover;
    margin-right:200px;
  }

  .nav{
    width:840px;
    height:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav .nav-item{
    height:100%;
  }

  .nav .nav-item a{
    display: block;
    height:100%;
    line-height: 103px;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    padding:0 32px;
    border-bottom:1px solid transparent;
    position: relative;
    transition: 0.2s linear;
  }

  .nav .nav-item.active a{
    color: #F8D100;
    border-bottom-color:#F8D100;
  }

  .nav .nav-item:hover a{
    transform: translateY(-5px);
  }

  .contact{
    width:610px;
    height:159px;
    background:url("../../assets/images/common/code1.png") no-repeat center/contain;
    position: absolute;
    right:-15px;
    display: none;
    justify-content: space-around;
    align-items: center;
  }

  .nav .nav-item:nth-child(5):hover .contact,
  .contact:hover{
    display: flex;
  }

  .contact .item{
    width: 113px;
    height: 113px;
    background: #FFFFFF;
    border: 1px solid #F8D100;
    border-radius: 10px;
    overflow: hidden;
  }

  .contact .item img{
    width:100%;
    height:100%;
    display: block;
  }

</style>
