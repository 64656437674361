<template>
  <div class="media">
    <div class="slogan"></div>
    <div class="media-box">
      <a href="javascript:;" class="btn-more" @click="clickMore"></a>
      <div class="media-swiper swiper">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in mediaList" :key="index" class="swiper-slide" @click="showDia(item)">
            <img :src="`${api}${item.advertisementArr[0].advertisement_img}`" :alt="item.title" />
          </div>
        </div>
      </div>
    </div>
    <div class="dialog" id="dialog" v-if="showDialog" :style="{width: `${width}px`, left: '50%', marginLeft: `-${width/2}px`}">
      <div class="close">
        <a href="javascript:;" class="icon-close" @click="closeDia"></a>
      </div>
      <img :src="showImg" alt="" />
    </div>
  </div>
</template>

<script>
import  Swiper, { Autoplay } from 'swiper'
import { fetchPost } from "@/api/https"

Swiper.use([Autoplay]);
export default {
  name: 'Media',
  props: {

  },
  data() {
    return {
      width:null,
      swiper:null,
      mediaList:[],
      api: 'https://admin.ilogin.com.cn/',
      showImg: '',
      showDialog: false
    }
  },
  methods: {
    initSwiper(){
      if (this.swiper !== null) return;
      this.swiper = new Swiper ('.media-swiper', {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren:true,
      })
    },
    clickMore(){
      let routeUrl =this.$router.resolve({path: '/MediaList'})
      window.open(routeUrl.href, '_blank');
    },
    handleGetImgList(){
      fetchPost('adVideoImg').then(response => {
        this.mediaList = response.data.data.data
      })
    },
    showDia(item) {
      this.width = document.body.clientWidth;
      this.showImg = `${this.api}${item.advertisementArr[0].advertisement_img}`
      this.showDialog = true
    },
    closeDia(){
      this.showImg = ``
      this.showDialog = false
    }
  },
  mounted() {
    this.handleGetImgList()
    this.$nextTick(() =>{
      this.initSwiper();
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .media{
    height:1086px;
    padding-top:72px;
  }

  .media .slogan{
    width:600px;
    height:147px;
    background:url("../../assets/images/home/title-media.png") no-repeat center/contain;
    margin:0 auto 66px;
  }

  .media .media-box{
    width: 1518px;
    height:779px;
    background:url("../../assets/images/home/bg-mediaCover.png") no-repeat center/cover;
    /*overflow: hidden;*/
    margin:0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .media-box .media-swiper{
    width:1465px;
    height:727px;
    overflow: hidden;
  }

  .media-swiper .swiper-slide{
    width:100%;
    height:100%;
    overflow: hidden;
    cursor: pointer;
  }

  .media-swiper img{
    display: block;
    width:100%;
    height:100%;
  }

  .media-swiper .swiper-slide:hover img{
    filter:brightness(1.1);
    -webkit-filter:brightness(1.1);
  }

  .btn-more{
    width:152px;
    height:51px;
    background:url("../../assets/images/home/btn-more.png") no-repeat center/contain;
    position: absolute;
    top:-60px;
    right:24px;
  }

  .btn-more:hover{
    transform: scale(1.1);
  }


</style>
