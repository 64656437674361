<template>
  <div class="news">
    <div class="slogan"></div>
    <div class="main">
      <div class="poster">
        <div class="poster-swiper">
          <div class="swiper-wrapper">
            <div v-for="(item, index) in posterList" :key="index" class="swiper-slide">
              <img :src="`${api}${item.advertisementArr[0].advertisement_img}`" :alt="item.title"/>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="list-tab">
          <div :class="['tab', showList === 0 ? 'active' : '']" @click="changeList(0)">新闻</div>
          <i>/</i>
          <div :class="['tab', showList === 1 ? 'active' : '']" @click="changeList(1)">公告</div>
          <a href="javascript:;" class="icon-more" @click="clickMore()"></a>
        </div>
        <ul class="news-list">
          <li v-for="(item, index) in  showList === 0 ? newsList : noticeList" :key="index"
              @click="openDetails(item.id)">
            <div class="cover">
              <img :src="`${api}${item.cover_img}`" alt="新闻配图"/>
            </div>
            <div class="info">
              <h2 class="time">{{ item.release_time | strReplace }}</h2>
              <h1 class="title">
                <span class="label">[{{ showList === 0 ? '新闻' : '公告' }}]</span>
                {{ item.title }}
              </h1>
              <p class="des">{{ item.content | componentFilter }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, {Autoplay} from 'swiper'
import {fetchPost} from "@/api/https"

Swiper.use([Autoplay]);
export default {
  name: 'News',
  props: {},
  data() {
    return {
      swiper: null,
      api: 'https://admin.ilogin.com.cn/',
      posterList: [],
      showList: 1,
      //活动资讯
      newsList: [],
      //公告资讯
      noticeList: []
    }
  },
  filters: {

    componentFilter: function (value) {
      value = value.replace(/<[^<>]+>/g, '')
      value = value.replace(/[\'\"\\\/\b\f\n\r\t\'&ldquo;'\'nbsp'\'&rdquo;'\']/g, '') // eslint-disable-line
      return value
    },
    strReplace: function (time) {
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(time)[0]
    }
  },
  create() {

  },
  methods: {
    initSwiper() {
      if (this.swiper !== null) return;
      this.swiper = new Swiper('.poster-swiper', {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: false,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      })
    },
    changeList(i) {
      this.showList = i;
    },
    openDetails(id) {
      let routeUrl = this.$router.resolve({path: '/NewsDetails', query: {newsId: id}})
      window.open(routeUrl.href, '_blank');
    },
    clickMore() {
      let routeUrl = this.$router.resolve({path: '/NewsList'})
      window.open(routeUrl.href, '_blank');
    },
    handleGetPoster() {
      fetchPost('adIndexRotation').then(response => {
        this.posterList = response.data.data.data
      })
    },
    handleGetNewsList() {
      fetchPost('imgTextNews').then(response => {
        this.newsList = response.data.data.data
      })
    },
    handleGetNoticeList() {
      fetchPost('imgTextActive').then(response => {
        this.noticeList = response.data.data.data
      })
    }
  },
  mounted() {
    this.handleGetPoster()
    this.handleGetNewsList()
    this.handleGetNoticeList()
    this.$nextTick(() => {
      this.initSwiper()
      // this.handleGetPosterList(fetchPost)
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news {
  height: 1080px;
  padding-top: 80px;
}

.slogan {
  width: 600px;
  height: 147px;
  background: url("../../assets/images/home/title-news.png") no-repeat center/contain;
  margin: 0 auto 28px;
}

.main {
  width: 1514px;
  height: 789px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.poster {
  height: 684px;
  width: 664px;
  border: 2px solid #FEF1C3;
  overflow: hidden;
}

.poster-swiper {
  width: 100%;
  height: 100%;
}

.poster-swiper .swiper-slide {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.swiper-slide::before {
  content: '';
  width: 0;
  height: 0;
  opacity: 0;
  border: 1px solid #FEF1C3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  pointer-events: none;
}

.swiper-slide img {
  display: block;
  height: 100%;
  transition: 2s line;
}

.swiper-slide:hover::before {
  opacity: 1;
  width: 643px;
  height: 663px;
}

.swiper-slide:hover img {
  transform: scale(1.1);
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.container {
  width: 820px;
  /*height:100%;*/
}

.list-tab {
  width: 100%;
  margin-bottom: 26px;
  border-bottom: 1px dashed #8F7109;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
}

.list-tab .tab {
  padding: 10px;
  font-size: 30px;
  font-weight: 500;
  color: #FFF;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.list-tab .tab.active {
  color: #FBEB00;
  border-bottom: 3px solid #FBEB00;
}

.list-tab .tab:hover {
  color: #FBEB00;
}

.list-tab i {
  font-size: 30px;
  font-weight: 500;
  color: #FBEB00;
  margin: 0 60px;
}

.list-tab .icon-more {
  width: 26px;
  height: 28px;
  background: url("../../assets/images/home/icon-more.png") no-repeat center/contain;
  margin-left: 66px;
}

.news-list {
  width: 100%;
  height: 684px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: hidden;
}

.news-list li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.news-list .cover {
  width: 290px;
  height: 154px;
  border: 2px solid #FEF1C3;
  overflow: hidden;
  position: relative;
}

.news-list .cover::before {
  content: '';
  width: 0;
  height: 0;
  opacity: 0;
  border: 1px solid #FEF1C3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  pointer-events: none;
}

.news-list li:hover .cover::before {
  width: 280px;
  height: 144px;
  opacity: 1;
}

.news-list .cover img {
  display: block;
  width: 100%;
  height: 100%;
}

.news-list li:hover img {
  transform: scale(1.1);
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.news-list .info {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}

.info .time {
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 52px;
}

.info .title {
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 52px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info .title .label {
  color: #FBEB00;
}

.info .des {
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 26px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.news-list li:hover .des {
  color: #FBEB00;
}
</style>
