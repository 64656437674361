<template>
  <div class="kv" :style="{ backgroundImage: `url( ${kvImg} )` }">
    <a v-if="false" href="https://www.taptap.com/app/152653/topic" class="btn-order" target="_blank"></a>
    <span class="icon-mouse"></span>
    <video v-if="isShowVideo" :src="videoSrc" id="video" class="kvVideo" muted autoplay/>
    <img src="../../assets/images/common/code.png" alt="" class="code">
  </div>
</template>

<script>
import {fetchPost} from "@/api/https"

export default {
  name: 'Kv',
  props: {},
  data() {
    return {
      isShowVideo: true,
      api: 'https://admin.ilogin.com.cn/',
      videoSrc: '',
      kvImg: '',
      kvData: []
    }
  },
  mounted() {
    this.handleGetKV()
    this.handleListenerVideo();
  },
  methods: {
    handleGetKV() {
      fetchPost('baseInfo').then(response => {
        this.kvData = response?.data?.data
        if (this.kvData[5].optionname === 'pc_video_kv') {
          if (!this.kvData[5].optionvalue || this.kvData[5].optionvalue === '' || this.kvData[5].optionvalue === null) {
            this.isShowVideo = false
          } else {
            this.videoSrc = this.api + this.kvData[5].optionvalue
          }
        }
        if (this.kvData[4].optionname === 'pc_index_kv') {
          if (this.kvData[4].optionvalue || this.kvData[4].optionvalue !== '' || this.kvData[4].optionvalue !== null) {
            this.kvImg = this.api + this.kvData[4].optionvalue
          }
        }
      })
    },
    handleListenerVideo() {
      const that = this;
      const eleVideo = document.getElementById('video');
      eleVideo.addEventListener('ended', function () {
        that.isShowVideo = false
      }, false)
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.kv {
  height: 1080px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 1080px;
}

.btn-order {
  width: 528px;
  height: 98px;
  background: url("../../assets/images/home/btn-goTAP.png") no-repeat center/contain;
  position: absolute;
  top: 950px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.kvVideo {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.btn-order:hover {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1)
}

.code {
  position: absolute;
  right: 64px;
  top: 218px;
  z-index: 99;
}

.icon-mouse {
  width: 100px;
  height: 138px;
  background: url("../../assets/images/home/icon-mouse.png") no-repeat center/contain;
  position: absolute;
  bottom: 155px;
  right: 115px;
  animation: showUp linear 1s infinite;
  z-index: 99;
}
</style>
