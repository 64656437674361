<template>
  <div class="footer">
    <p>备案号：琼ICP备2021009005号-1【出版单位】金报电子音像出版中心【出版物号】ISBN 978-7-498-08450-7【审批文号】国新出审【2020】2784 号</p>
    <p>海南艾洛央青科技有限公司版权所有</p>
    <p>开发者：上海润梦网络科技有限公司  联系方式：{{phoneInfo | componentFilter}}</p>
    <p>公司地址：海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼四楼1001室</p>
    <div class="link-box">
      <p>版本号：{{editionInfo | componentFilter}}</p>
      <p>更新时间：{{timeInfo | componentFilter}}</p>
      <a href="javascript:;" @click="openDetails(hideInfo)">隐私协议</a>
      <a href="javascript:;" @click="openDetails(powerInfo)">用户权限</a>
    </div>
  </div>
</template>

<script>
import { fetchPost } from "@/api/https"

export default {
  name: "footerBat",
  data() {
    return {
      editionInfo: '',
      timeInfo: '',
      phoneInfo: '',
      powerInfo: Number,
      hideInfo: Number
    }
  },
  filters:{
    componentFilter:function(value){
      value = value.replace(/<[^<>]+>/g,'')
      // value = value.replace(/[\'\"\\\/\b\f\n\r\t\'&ldquo;'\'nbsp'\'&rdquo;'\']/g, '') // eslint-disable-line
      return value
    },
    strReplace:function (time){
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(time)[0]
    }
  },
  mounted() {
    this.getEdition()
    this.getTime()
    this.getPhone()
    this.getPower()
    this.getHide()
  },
  methods:{
    getEdition(){
      fetchPost('editionInfo').then(response => {
        this.editionInfo = response?.data?.data?.content
      })
    },
    getTime(){
      fetchPost('timeInfo').then(response => {
        this.timeInfo = response?.data?.data?.content
      })
    },
    getPhone(){
      fetchPost('phoneInfo').then(response => {
        this.phoneInfo = response?.data?.data?.content
      })
    },
    getPower(){
      fetchPost('powerInfo').then(response => {
        this.powerInfo = response?.data?.data?.id
      })
    },
    getHide(){
      fetchPost('hideInfo').then(response => {
        this.hideInfo = response?.data?.data?.id
      })
    },
    openDetails(id){
      let routeUrl =this.$router.resolve({path: '/NewsDetails', query: {  newsId: id }})
      window.open(routeUrl.href, '_blank');
    },
  }
}
</script>

<style scoped>
.footer{
  /*position: absolute;*/
  /*left:0;*/
  /*bottom:-100px;*/
  width:100%;
  /*height:;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*align-content: center;*/
  /*flex-wrap: wrap;*/
  background: #000;
  margin-top:-1px;
  padding:20px 0;
}

.footer p{
  width:100%;
  text-align: center;
  font-size:20px;
  color:#fff;
}
.footer .link-box{
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .link-box *{
  width:auto;
  font-size:20px;
  color:#fff;
  margin:0 20px;
}
</style>
